import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'
// import ShopCategory from "../components/ecwid/category"

const RewardsPage = ({ data }) => (
	<Layout
		pathname="/rewards-points-information/"
		head={{
			name: 'Rewards Points Information',
			meta: {
				description:
					'Rewards Points Information This is a new feature of our recently upgraded website. Register as a Buck Woodcraft customer at check out, and get 1 Reward...',
			},
		}}
	>
		<div className="container py-5">
			<div className="row">
				{/*<div className="col-md-4">
							<h2 className="section-heading">Categories</h2>
							<ShopCategory/>
						</div>*/}
				<div className="col-md-8 shop-items mx-auto">
					<div className="page-content">
						<h4 className="section-heading page-mt">
							Rewards Points Information
						</h4>
						<p>
							This is a new feature of our recently upgraded website. Register
							as a Buck Woodcraft customer at check out, and get 1 Reward Point
							for each dollar you spend except for shipping & sales taxes.
							Reward Points equal 3.3% of your purchase amount, and can be used
							just like cash toward the cost of any other items you want to buy
							at a later date -- and they&#39;ll accumulate with each purchase
							until you redeem them as partial or full payment for any future
							purchases you make.
						</p>
						<p>
							In short, the more you buy, the more you can save on future
							purchases.
						</p>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default RewardsPage

export const categorynodeQuery = graphql`
	query {
		allStrapiCategories(sort: { fields: id, order: ASC }) {
			edges {
				node {
					id
					name
					url
					subcategories {
						name
						url
					}
				}
			}
		}
	}
`
